<template>
  <v-app>
    <default-bar v-if="shouldShowDefaultViews()" />

    <default-drawer v-if="shouldShowDefaultViews()" />

    <default-view />

    <default-footer v-if="shouldShowDefaultViews()" />
  </v-app>
</template>

<script>
  export default {
    name: 'DefaultLayout',

    components: {
      DefaultBar: () => import(
        /* webpackChunkName: "default-app-bar" */
        './AppBar'
      ),
      DefaultDrawer: () => import(
        /* webpackChunkName: "default-drawer" */
        './Drawer'
      ),
      DefaultFooter: () => import(
        /* webpackChunkName: "default-footer" */
        './Footer'
      ),
      DefaultView: () => import(
        /* webpackChunkName: "default-view" */
        './View'
      ),
    },

    methods: {
      shouldShowDefaultViews: function () {
        const pattern = /login\/*/
        return (this.$route.path !== '/' && !pattern.test(this.$route.path))
      },
    },
  }
</script>
